import React from "react"
const Enduser = () => {
  return (
    <>
      <div className="docs">
        <h2 id="mobile-app-end-user-license-agreement">
          Mobile App End-User License Agreement
        </h2>
        <p>
          This End User License Agreement (“Agreement”) is between you and Shots
          Fired BV and governs use of this app made available through the Apple
          App Store. By installing the New Dance TV App, you agree to be bound
          by this Agreement and understand that there is no tolerance for
          objectionable content. If you do not agree with the terms and
          conditions of this Agreement, you are not entitled to use the New
          Dance TV App.
        </p>
        <p>
          In order to ensure New Dance TV provides the best experience possible
          for everyone, we strongly enforce a no tolerance policy for
          objectionable content. Content displayed in our app is reviewed by our
          team and other members of the community.
        </p>
        <ol>
          <li>
            Parties: This Agreement is between you and NEw Dance TV only, and
            not Apple, Inc. (“Apple”). Notwithstanding the foregoing, you
            acknowledge that Apple and its subsidiaries are third party
            beneficiaries of this Agreement and Apple has the right to enforce
            this Agreement against you. New Dance TV, not Apple, is solely
            responsible for the New Dance TV App and its content.
          </li>
          <li>
            Privacy: New Dance TV may collect and use information about your
            usage of the New Dance TV App, including certain types of
            information from and about your device. New Dance TV may use this
            information, as long as it is in a form that does not personally
            identify you, to measure the use and performance of the New Dance TV
            App. More information about how New Dance TV uses this information
            can be found in our{" "}
            <a href="./privacy">
              Privacy Policy
            </a>
            .
          </li>
          <li>
            Limited License: New Dance TV grants you a limited, non-exclusive,
            non-transferable, revocable license to use the New Dance TV App for
            your personal, non-commercial purposes. You may only use the New
            Dance TV App on Apple devices that you own or control and as
            permitted by the App Store Terms of Service.
          </li>
          <li>
            Age Restrictions: By using the New Dance TV App, you represent and
            warrant that (a) you are 17 years of age or older and you agree to
            be bound by this Agreement; (b) if you are under 17 years of age,
            you have obtained verifiable consent from a parent or legal
            guardian; and (c) your use of the New Dance TV App does not violate
            any applicable law or regulation. Your access to the New Dance TV
            App may be terminated without warning if New Dance TV believes, in
            its sole discretion, that you are under the age of 17 years and have
            not obtained verifiable consent from a parent or legal guardian. If
            you are a parent or legal guardian and you provide your consent to
            your child&#39;s use of the New Dance TV App, you agree to be bound
            by this Agreement in respect to your child&#39;s use of the New
            Dance TV App.
          </li>
          <li>
            Objectionable Content Policy: Content may not be submitted to New
            Dance TV, who will moderate all content and ultimately decide
            whether or not to post a submission to the extent such content
            includes, is in conjunction with, or alongside any, Objectionable
            Content. Objectionable Content includes, but is not limited to: (i)
            sexually explicit materials; (ii) obscene, defamatory, libelous,
            slanderous, violent and/or unlawful content or profanity; (iii)
            content that infringes upon the rights of any third party, including
            copyright, trademark, privacy, publicity or other personal or
            proprietary right, or that is deceptive or fraudulent; (iv) content
            that promotes the use or sale of illegal or regulated substances,
            tobacco products, ammunition and/or firearms; and (v) gambling,
            including without limitation, any online casino, sports books, bingo
            or poker.
          </li>
          <li>
            Warranty: New Dance TV disclaims all warranties about the New Dance
            TV App to the fullest extent permitted by law. To the extent any
            warranty exists under law that cannot be disclaimed, New Dance TV,
            not Apple, shall be solely responsible for such warranty.
          </li>
          <li>
            Maintenance and Support: New Dance TV does provide minimal
            maintenance or support for it but not to the extent that any
            maintenance or support is required by applicable law, New Dance TV,
            not Apple, shall be obligated to furnish any such maintenance or
            support.
          </li>
          <li>
            Product Claims: New Dance TV, not Apple, is responsible for
            addressing any claims by you relating to the New Dance TV App or use
            of it, including, but not limited to: (i) any product liability
            claim; (ii) any claim that the New Dance TV App fails to conform to
            any applicable legal or regulatory requirement; and (iii) any claim
            arising under consumer protection or similar legislation. Nothing in
            this Agreement shall be deemed an admission that you may have such
            claims.
          </li>
          <li>
            Third Party Intellectual Property Claims: New Dance TV shall not be
            obligated to indemnify or defend you with respect to any third party
            claim arising out or relating to the New Dance TV App. To the extent
            New Dance TV is required to provide indemnification by applicable
            law, New Dance TV, not Apple, shall be solely responsible for the
            investigation, defense, settlement and discharge of any claim that
            the New Dance TV App or your use of it infringes any third party
            intellectual property right.
          </li>
        </ol>
        <p>Relevant legal documentation:</p>
        <ul>
          <li>
            <a href="./privacy">
              Privacy Policy
            </a>
            <span> link</span>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Enduser
